import $ from 'jquery';
import barba from '@barba/core';
import App from '../app';

export default function () {
  barba.hooks.beforeEnter(({current, next, trigger}) => {
    const app = new App();
    const $trigger = $(trigger).parent('li');
    app.init();
    // Nav active states
    $trigger.addClass('is-active').siblings('li').removeClass('is-active');
    // Remove old class from body
    if (current.namespace !== '') {
      barba.wrapper.classList.remove(current.namespace);
    }
    // remove any li active states for homepage
    if (next.namespace === 'home' || next.namespace === 'termsConditions' || next.namespace === 'privacyPolicy') {
      $('.nav__list li').removeClass('is-active');
    }
    // Add Class of next page to body
    barba.wrapper.classList.add(next.namespace);
    // reset scroll position
    $(window).scrollTop(0);
  });

  barba.hooks.afterEnter(() => {
    $('body').removeClass('menu-is-open');
  });

  barba.init({
    timeout: 5000,
    preventRunning: true,
    prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
  });
}
