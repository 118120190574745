import 'intersection-observer';
import $ from 'jquery';
import pageTransitions from './components/pageTransitions';
import VideoPlayer from './components/videoPlayer';
import Header from './components/header';
import Subscribe from './components/subscribe';
import InView from './components/inview';
import ToggleDisplay from './components/toggleDisplay';

export default class App {
    constructor() {
        this.components = {
            header: Header,
            videoPlayer: VideoPlayer,
            toggleDisplay: ToggleDisplay,
            inView: InView,
        };
    }

    init() {
        const components = this.components;

        $('[data-component]').each(function () {
            const node = this;
            $(node)
                .data('component')
                .split(/\s+/)
                .forEach((componentName) => {
                    const component = components[componentName];
                    if (component) {
                        new component(node);
                    }
                });
        });
    }
}

$(function () {
    const header = document.getElementById('header');
    const form = document.getElementById('mc-subscribe-form');
    new Header(header);
    new Subscribe(form);
    // Instantiate Barba.js
    pageTransitions();
});
