import $ from 'jquery';
import inView from 'in-view';

class InView {
  constructor() {
    this.body = $('body');
    // Set offset value
    inView.offset(100);
    // Instantiate inView 
    inView('.slant')
      .on('enter', el => {
        const $el = $(el);
        const section = $el.attr('data-section');
        this.body.addClass(`active-section-${section}`);
        $el.addClass('is-active');
      })
      .on('exit', el => {
        const $el = $(el);
        const section = $el.attr('data-section');
        this.body.removeClass(`active-section-${section}`);
      });
      
    // Instantiate inView 
    inView('.reveal')
      .on('enter', el => {
        const $el = $(el);
        $el.addClass('reveal--ed');
      });
  }
}

export default InView;
