import $ from 'jquery';

class Subscribe {
  constructor(node) {
    this.$node = $(node);
    this.$email = $('#mce-EMAIL');
    this.$errorMessage = $('#mce-error-response');
    this.$successMessage = $('#mce-success-response');
    this.$notice = $('#notice');
    this.eventHandlers();
  }

  eventHandlers() {
    this.$node.on('submit', (e) => {
      e.preventDefault();
      if (this.$email.val() === '') {
        alert('Please enter an email address');
        return;
      }
      this.postFormData();
    });
  }

  postFormData() {
    $.ajax({
      type: 'GET',
      url: this.$node.attr('action').replace('/post?', '/post-json?'),
      data: this.$node.serialize(),
      cache: false,
      dataType: 'jsonp',
      jsonp: 'c',
      contentType: 'application/json; charset=utf-8',
      error: function() {
        alert('Could not connect to the registration server. Please try again later.');
      },
      success: (data) => {
        this.$notice.addClass('is-active');

        if (data.result === 'success') {
          this.$errorMessage.hide();
          this.$successMessage.text('You’re now subscribed, Thank you!').show();
        } else {
          this.$successMessage.hide();
          if (data.msg.includes('is already subscribed')) {
            this.$errorMessage.text('You’re already subscribed').show();
          } else {
            this.$errorMessage.text(data.msg).show();
          }
        }

        this.$email.val('');

        const delay = setTimeout(() => {
          this.$notice.removeClass('is-active');
          clearTimeout(delay);
        }, 2500);
      }
    });
  }
}

export default Subscribe;
