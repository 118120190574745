import $ from 'jquery';

class Header {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.nav = this.$node.find('.nav__list');
    this.navItem = this.$node.find('.nav-item');
    this.menuButton = this.$node.find('.header__menu-icon-wrapper');
    this.body = $('body');
    this.eventHandlers();

    if (! this.body.hasClass('home')) {
      this.$node.addClass('background-inherit');
    }
  }

  eventHandlers() {
    const that = this; 
    // Scrolling event
    $(window).on('scroll', () => this.scrollHandler());
    // Nav item hover event
    if ($('.no-touchevents').length) {
      this.navItem.hover(
        function() {
          const backgroundColor = $(this).data('colour');
          that.body.css({ backgroundColor }).addClass('nav-is-hovered');
        },
        () => {
          this.body.removeAttr('style').removeClass('nav-is-hovered');
        }
      ); 
    }

    this.menuButton.on('click', () => {
      if (this.body.hasClass('menu-is-open')) {
        this.body.removeClass('menu-is-open');
      } else {
        this.body.addClass('menu-is-open');
      }
    });
  }

  scrollHandler() {
    if (window.pageYOffset > 10) {
      this.body.addClass('is-scrolling');
    } else {
      this.body.removeClass('is-scrolling');
    }

    if (this.body.hasClass('home') && window.pageYOffset >= 680) {
      this.$node.removeClass('home-scroll');
      this.$node.addClass('background-inherit');
    } else if (this.body.hasClass('home') && window.pageYOffset < 680) {
      if (window.pageYOffset > 10) {
        this.$node.addClass('home-scroll');
      } else {
        this.$node.removeClass('home-scroll');
        this.$node.removeClass('background-inherit');
      }
    } else {
      this.$node.removeClass('home-scroll');
      this.$node.addClass('background-inherit');
    }
  }
}

export default Header;
