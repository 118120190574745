import $ from 'jquery';

class ToggleDisplay {
  constructor(node) {
    const $node = $(node);
    this.$node = $node;
    this.eventHandlers();
  }

  eventHandlers() {
    this.$node.on('click', function() {
      const $this = $(this);
      const $container = $this.parents('.toggle-parent');

      if ($container.hasClass('is-open')) {
        $container.removeClass('is-open');
      } else {
        $container.addClass('is-open');
      }
    });
  }
}

export default ToggleDisplay;
